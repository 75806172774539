<template>
  <div class="users-view">
    <Banner>
      <div class="banner-content">
        <MeepIconSettings class="banner-content__icon" />
        <span class="banner-content__text">{{ $t("menu.setting") }}</span>
      </div>
    </Banner>
    <div class="users-view-content page-layout">
      <UserForm ref="userForm" :oldUserData="oldUser" :userData="user">
        <template #header>
          <PageHeader
            :has-actions="true"
            :has-back="true"
            :title="$t('users.view.title')"
            @back="goBack"
          >
            <md-button
              v-if="isAdmin"
              class="md-primary"
              type="button"
              @click="isTextModalOpen = true"
            >
              {{ $t("users.view.change-password-button") }}
            </md-button>
            <md-button
              class="md-primary"
              type="button"
              @click="isConfirmModalOpen = true"
            >
              {{ $t("users.view.delete-button") }}
            </md-button>
          </PageHeader>
        </template>
        <template #buttons>
          <md-button class="md-primary--inverted" @click.native="fetchData">
            {{ $t("form.cancel") }}
          </md-button>

          <md-button class="md-raised md-primary" @click.native="editUser">
            {{ $t("users.view.confỉm-button") }}
          </md-button>
        </template>
      </UserForm>

      <SetPasswordModal
        v-if="isTextModalOpen"
        :context="$t('users.set-password-modal.context')"
        :title="$t('users.set-password-modal.title')"
        @close="isTextModalOpen = false"
      />

      <confirm-action-modal
        v-if="isConfirmModalOpen"
        :object-to-act-upon="{}"
        :text="confirmModalText"
        @close="isConfirmModalOpen = false"
        @confirm="onConfirmModal"
      />
    </div>
  </div>
</template>

<script>
import usersModel from "../../model/users";
import { mapGetters } from "vuex";
import PageHeader from "@/components/PageHeader";
import companiesModel from "@/model/companies";
import { USERS, MODALS } from "@/constants";
import Banner from "@/components/Banner";
import MeepIconSettings from "@/components/icons/MeepIconSettings.vue";

import ForceLeaveMixin from "@/mixins/ForceLeaveMixin";

import { compareObjects, convertToTime, sortByKey } from "@/services/util";
import employeeModel from "@/model/employee";
import SetPasswordModal from "./SetPasswordModal.vue";
import ConfirmActionModal from "../../components/modal/confirm-action";
import _ from "lodash";
import UserForm from "./UserForm.vue";

export default {
  name: "editUser",

  components: {
    PageHeader,
    UserForm,
    Banner,
    MeepIconSettings,
    SetPasswordModal,
    ConfirmActionModal,
  },

  mixins: [ForceLeaveMixin],

  data() {
    return {
      isTextModalOpen: false,
      isConfirmModalOpen: false,
      confirmationContext: "trash",
      sexes: [
        { value: "Masculin", id: 1 },
        { value: "Féminin", id: 2 },
      ],

      contractTypes: [
        { text: "Contrat à durée indéterminée", value: "CDI" },
        { text: "Contrat à durée déterminée", value: "CDD" },
        { text: "Contrat de travail temporaire", value: "CTT" },
      ],

      user: {
        scope: null,
        gender: null,
        firstname: "",
        lastname: "",
        socialSecurityNumber: "",
        birthday: "",
        email: "",
        telfixe: "",
        telportable: "",
        adresse: "",
        ville: "",
        country: "",
        codepostal: null,
        department: "",
        company_ids: [],
        hireDate: null,
        contractType: "",
        salary: null,
        trialPeriod: null,
      },

      oldUser: {},

      newPassword: "",

      companies: [],
    };
  },

  computed: {
    ...mapGetters(["isAdmin", "isCollab", "isClient", "isJEPA"]),

    confirmModalText() {
      switch (this.confirmationContext) {
        case MODALS.TRASH:
          return {
            header: this.$t("users.view.modal-title"),
            body: () => {
              return this.$t("users.view.modal-body");
            },

            question: this.$t("users.view.modal-question"),
          };
        case MODALS.SAVE:
          return {
            header: this.$t("users.view.modal-title"),
            body: () => {
              return this.$t("user.view-update-modal.body");
            },
            question: this.$t("users.view.modal-question"),
          };
        default:
          return {
            header: this.$t("users.view.modal-title"),
            body: () => {
              return this.$t("users.view.edit-modal-body");
            },

            question: this.$t("users.view.edit-modal-question"),
          };
      }
    },

    isAnyModified() {
      return !compareObjects(this.user, this.oldUser);
    },
  },

  async mounted() {
    this.fetchData();
  },

  methods: {
    goBack() {
      window.history.back();
    },

    getCompanyName(companyId) {
      const company = this.companies.find(company => company.id === companyId);

      return company ? company.name : "";
    },

    async fetchData() {
      try {
        const companies = await companiesModel.getAll();
        this.user = await usersModel.get(this.$route.params.id);
        this.user.company_ids = this.user.companies.map(
          companyObject => companyObject.id,
        );

        if (this.user.scope === USERS.EMPLOYEE) {
          const employee = await employeeModel.getEmployee(
            this.$route.params.id,
          );
          employee.birthday = convertToTime(employee.birthday);
          employee.hireDate = convertToTime(employee.hireDate);
          employee.endDate = convertToTime(employee.endDate);
          employee.country = employee.birthCountry;
          this.user = { ...this.user, ...employee };
        }

        this.oldUser = _.cloneDeep(this.user);

        this.companies = await sortByKey(companies, "name");
      } catch (error) {
        console.log(error);
      }
    },

    async editUser() {
      this.confirmationContext = MODALS.SAVE;
      this.openConfirmModal();
    },

    openConfirmModal() {
      this.isConfirmModalOpen = true;
    },

    closeConfirmModal() {
      this.isConfirmModalOpen = false;
    },

    onConfirmModal() {
      switch (this.confirmationContext) {
        case MODALS.TRASH:
          this.trashUser();
          break;
        case MODALS.SAVE:
          this.confirmEditUser();
          break;
        default:
          this.forceRouteLeave();
      }
    },
    // confirm action edit information user
    async confirmEditUser() {
      const result = !this.$refs.userForm.$v.$invalid;
      if (result) {
        try {
          await usersModel.update(this.user);
          this.$toasted.global.AppSucces({
            message: this.$t("information.success-message"),
          });
          this.nextPath = "/dashboard/setting/users/";
          this.forceRouteLeave();
        } catch (err) {
          this.$toasted.global.AppError({
            message: err.msg,
          });
        }
      } else {
        this.$refs.userForm.$v.$touch();
        this.$toasted.global.AppInfo({
          message: this.$t("error.required"),
        });
      }
    },

    async trashUser() {
      try {
        await usersModel.putInTrash(
          this.user.user_id ? this.user.user_id : this.user.id,
        );
        this.$toasted.global.AppError({
          message: "Success",
        });
        this.$router.push("/dashboard/setting/users");
      } catch (error) {
        console.log(error);
        this.$toasted.global.AppError({
          message: error.msg,
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/variable";

#app .users-view {
  &__form.md-layout-item {
    padding-bottom: 60px;
  }

  .users-view-content .md-card.md-theme-default .md-card-content {
    align-items: unset;

    .meep-form-column:nth-child(2) {
      display: flex;

      .md-alignment-center-right {
        flex: 1;
        align-items: flex-end;
      }
    }
  }

  .select-error {
    color: var(--primary, #ff1744);

    &--small {
      font-size: 12px;
    }
  }

  &__list-item {
    color: var(--bg-primary);
  }

  &__card {
    padding: 0 25%;

    @media (max-width: 600px) {
      padding: 0;
    }
  }

  &-content {
    .page-header {
      &__actions {
        .md-button.md-primary {
          padding: 0 20px;
          font-size: toRem(9);
          @include for-lg {
            font-size: toRem(17);
          }
        }
      }
    }

    .md-card-content {
      .md-layout.create-button {
        .md-button {
          padding: 0 20px;
        }
      }

      .meep-form-title {
        margin-bottom: toRem(15);
        @include for-lg {
          margin-bottom: toRem(30);
        }
      }
    }
  }

  .create-button {
    margin-top: 50px;
    flex-direction: row;
  }

  .meep-annoucement {
    margin-top: 10px;
  }

  .meep-input {
    .md-has-placeholder.md-has-password {
      .md-button {
        top: 5px;
        @include for-lg {
          top: 10px;
        }

        .md-ripple {
          padding: 0;
        }
      }
    }
    .md-has-placeholder.md-has-password.md-has-value{
      .md-input{
        padding: 0 0 0 toRem(19);
      }
    }
  }
}
</style>
